<template>
  <div class="appoinmentCalendar">
    <a-calendar  v-model:value="value" :fullscreen="false" @select="onSelect" @panelChange="onPanelChange" />
  </div>
  
</template>
<script>
import { defineComponent, ref, computed, watchPostEffect } from 'vue';
import { useStore } from "vuex"
import moment from "moment"
export default defineComponent({
  props:{
    dateSelected:String
  },
  setup(props, {emit}) {
    const store = useStore()
    const value = ref();
    const date = computed(() =>{
      return moment(store.state.appointment.calendarDate)
    })
    const selectedValue = ref();
    const onSelect = value => {
      store.state.appointment.calendarDate = value;
      selectedValue.value = value;
      emit("is-click",value)
      
    };
    watchPostEffect(()=>{
      value.value = props.dateSelected
      //onSelect(props.dateSelected)
    })
    const onPanelChange = (value, mode) => {
      emit("is-click",value)
      console.log(value, mode , store.state.appointment.calendarDate);
    };

    return {
      value,
      date,
      selectedValue,
      onSelect,
      onPanelChange,
    };
  },

});
</script>
<style lang="scss">
.appoinmentCalendar {
  width: 300px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  .ant-select-dropdown {
    top: 100px !important;
  }
}
</style>